import PropTypes from 'prop-types';
import { useEffect, useState, useContext } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Drawer,
  Typography,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  CircularProgress,
  Accordion,
  AccordionSummary,
  DialogActions,
  AccordionDetails,
  Stack,
  Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast } from 'react-toastify';
// components
import { AiOutlineLogout, AiFillApi, AiFillLock, AiFillUnlock } from 'react-icons/ai';
import { BsFillHddNetworkFill, BsFillCloudsFill } from 'react-icons/bs';
import { RiToolsFill } from 'react-icons/ri';
import { FcUnlock } from 'react-icons/fc';
import { MdRestartAlt, MdSignalWifi4Bar } from 'react-icons/md';
import { GiShieldBash, GiShieldBounces, GiChessKing } from 'react-icons/gi';
import { FaRobot } from 'react-icons/fa';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
import { SocketContext } from '../../services/socket/socket';
//
import UserStatus from './UserStatus';
//
import useUserStore from '../../features/stores/user';
import useConfigsStore from '../../features/stores/settings';
import UnlockIpDialog from './UnlockIpDialog';
import TimerOnScreenModal from './TimerOnScreenModal';
import CalcAcessoModal from './CalcAcessoModal';
import sidebarConfigAdmin from './SidebarConfigAdmin';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 200;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const socket = useContext(SocketContext);
  const { userData } = useUserStore();
  const {
    restartNginx,
    restartApi,
    unlockIP,
    op,
    fetchConfig,
    enableApiProtect,
    unlockAllIps,
    enableCloudProtect
  } = useConfigsStore();
  const { pathname } = useLocation();
  // const [dateState, setDateState] = useState(new Date());
  const navigate = useNavigate();
  // useEffect(() => {
  //   setInterval(() => setDateState(new Date()), 1000);
  // }, []);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleLogout = async (e) => {
    e.preventDefault();
    sessionStorage.clear();
    const id = userData[0].sub;
    socket.emit('updateUserStatus', { id, status: 'Offline' });
    localStorage.clear();
    navigate('/login', { replace: true });
    window.location.reload();
  };

  const notify = (args) => {
    toast(`☁️ Cloud Protection ${args}`, {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const handleEnableApiProtection = async (id, status) => {
    await enableApiProtect(id, !status);
  };

  const handleEnableCloudProtection = async (id, status) => {
    const result = await enableCloudProtect(id, !status);
    notify(result.cloudProtect ? 'ativada! ✅' : 'desativada! ❌');
  };

  const Greeting = () => {
    const greetingMessage = () => {
      const h = new Date().getHours();
      if (h <= 5) return 'Boa madrugada';
      if (h < 12) return 'Bom dia';
      if (h < 18) return 'Boa tarde';
      return 'Boa noite';
    };

    return (
      <Typography
        variant="subtitle2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: 'text.primary'
        }}
      >
        {greetingMessage()},
      </Typography>
    );
  };

  const [showDialog, setShowDialog] = useState(false);
  const [showDialogNginx, setShowDialogNginx] = useState(false);
  const [showDialogFlushIps, setShowDialogFlushIps] = useState(false);
  const [textAPI, setTextAPI] = useState('Aguarde um momento...');
  const [textNginx, setTextNGINX] = useState('Aguarde um momento...');
  const [showDialoUnlockIp, setShowDialoUnlockIp] = useState(false);

  const [showDialogMinimunTimer, setShowDialogMinimumTimer] = useState(false);
  const [showDialogCalc, setShowDialogCalc] = useState(false);

  const [textUnlockIp, setTextUnlockIp] = useState('Aguarde um momento...');
  const [ip, setIp] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingFlush, setLoadingFlush] = useState(false);

  const handleCloseIp = () => {
    setShowDialoUnlockIp(false);
  };

  const handleCloseModalMinimumTimer = () => {
    setShowDialogMinimumTimer(false);
  };

  const handleCloseModalCalc = () => {
    setShowDialogCalc(false);
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  const handleRestartNginx = () => {
    setShowDialogNginx(true);
    restartNginx();
    setTimeout(() => {
      setTimeout(() => {
        setShowDialogNginx(false);
        setTextNGINX('Aguarde um momento...');
      }, 1000);
      setTextNGINX('Nginx reiniciado!');
    }, 2000);
  };

  const handleRestartApi = () => {
    setShowDialog(true);
    restartApi();
    setTimeout(() => {
      setTimeout(() => {
        setShowDialog(false);
        setTextAPI('Aguarde um momento...');
      }, 1000);
      setTextAPI('Api Reiniciada!');
    }, 2000);
  };

  const openIpUnlock = () => {
    setShowDialoUnlockIp(true);
  };

  const handleDesblockAllIps = () => {
    setShowDialogFlushIps(true);
    setLoadingFlush(true);
    unlockAllIps();
  };

  const handleUnlockIp = () => {
    unlockIP(ip);
  };

  function MakeDialog() {
    return (
      <div>
        <Dialog
          open={showDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Reiniciando API</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>{textAPI}</Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  function MakeDialogNginx() {
    return (
      <div>
        <Dialog
          open={showDialogNginx}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Reiniciando Nginx</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>{textNginx}</Typography>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }

  function MakeDialogFlushIPS() {
    return (
      <div>
        <Dialog
          open={showDialogFlushIps}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {isLoadingFlush ? 'Desbloqueando IPs do servidor' : 'IPs desbloqueados do servidor'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {isLoadingFlush ? <CircularStatic /> : <Typography>Sucesso!</Typography>}
            </DialogContentText>
          </DialogContent>
          {!isLoadingFlush ? (
            <DialogActions>
              <Button onClick={() => setShowDialogFlushIps(false)} autoFocus>
                OK
              </Button>
            </DialogActions>
          ) : null}
        </Dialog>
      </div>
    );
  }

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired
  };

  function CircularStatic() {
    const [progress, setProgress] = useState(1);

    useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? setLoadingFlush(false) : prevProgress + 5
        );
      }, 90);
      return () => {
        clearInterval(timer);
      };
    }, []);

    return <CircularProgressWithLabel value={progress} />;
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: 'text.primary',
          px: 2.5,
          flexDirection: 'column'
        }}
      >
        &nbsp;
        <Greeting /> {userData && userData[0].nick}!&nbsp;
      </Typography>

      {userData && userData[0].role === 'ADMIN' ? (
        <Stack sx={{ width: '100%', px: 2.5 }}>
          <UserStatus />
        </Stack>
      ) : null}

      <div style={{ marginBottom: 10 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                fontWeight: 'bold',
                fontSize: 12,
                ml: 2
              }}
            >
              <RiToolsFill size={18} /> &nbsp;Ferramentas
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: 30,
                mt: 0.3
              }}
            >
              <Button
                sx={{ width: '100%', borderRadius: 0.5 }}
                variant="outlined"
                onClick={(e) => handleDesblockAllIps()}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: 11,
                    color: '#200B94',
                    fontWeight: 'bold'
                  }}
                >
                  <AiFillUnlock size={15} color="green" />
                  UNLOCK ALL IP's
                  <MdSignalWifi4Bar size={15} color="green" />
                </Typography>
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                height: 30,
                mt: 0.3
              }}
            >
              <Button
                sx={{ width: '49.5%', borderRadius: 0.5 }}
                variant="outlined"
                onClick={handleRestartNginx}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: 11,
                    color: '#200B94',
                    fontWeight: 'bold'
                  }}
                >
                  Nginx
                  <MdRestartAlt size={20} color="green" />
                </Typography>
              </Button>
              <Button
                sx={{ width: '49.5%', borderRadius: 0.5 }}
                variant="outlined"
                onClick={handleRestartApi}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: 11,
                    color: '#200B94',
                    fontWeight: 'bold'
                  }}
                >
                  API
                  <MdRestartAlt size={20} color="blue" />
                </Typography>
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: 30,
                mt: 0.3
              }}
            >
              <Button
                sx={{ width: '100%', borderRadius: 0.5 }}
                variant="outlined"
                onClick={(e) => openIpUnlock(e)}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: 11,
                    color: '#200B94',
                    fontWeight: 'bold'
                  }}
                >
                  <FcUnlock size={15} color="green" />
                  DESBLOQUEAR IP
                  <FcUnlock size={15} color="green" />
                </Typography>
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: 30,
                mt: 0.3
              }}
            >
              <Button
                component={RouterLink}
                to="/dashboard/blockedips"
                sx={{ width: '100%', borderRadius: 0.5, borderColor: 'red' }}
                variant="outlined"
              >
                <GiShieldBash size={20} color="red" />
                <Typography
                  sx={{
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    width: '80%',
                    fontSize: 13,
                    color: 'red'
                  }}
                >
                  Attack View
                </Typography>
                <GiShieldBounces size={20} color="red" />
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                height: 30,
                mt: 0.4
              }}
            >
              <Tooltip title="API PROTECTION - (ATIVE APENAS SE ESTIVER SOFRENDO ATACK)">
                <Button
                  onClick={(e) => handleEnableApiProtection(op.id, op.apiProtect)}
                  sx={{ width: '49.5%', borderRadius: 0.5 }}
                  variant="outlined"
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontSize: 11,
                      fontWeight: 'bold'
                    }}
                  >
                    <AiFillApi size={20} color={op.apiProtect ? 'green' : 'red'} />
                    {op.apiProtect ? (
                      <AiFillLock size={20} color="green" />
                    ) : (
                      <AiFillUnlock size={20} color="red" />
                    )}
                  </Typography>
                </Button>
              </Tooltip>
              <Tooltip title="CLOUD PROTECTION">
                <Button
                  sx={{ width: '49.5%', borderRadius: 0.5 }}
                  variant="outlined"
                  onClick={(e) => handleEnableCloudProtection(op.id, op.cloudProtect)}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      fontSize: 11,
                      color: '#200B94',
                      fontWeight: 'bold'
                    }}
                  >
                    <BsFillCloudsFill size={20} color={op.cloudProtect ? 'green' : 'red'} />
                    {op.cloudProtect ? (
                      <AiFillLock size={20} color="green" />
                    ) : (
                      <AiFillUnlock size={20} color="red" />
                    )}
                  </Typography>
                </Button>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: 30,
                mt: 0.3
              }}
            >
              <Button
                onClick={() => setShowDialogMinimumTimer(true)}
                sx={{ width: '100%', borderRadius: 0.5, borderColor: 'red' }}
                variant="outlined"
              >
                <Typography
                  sx={{
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    width: '80%',
                    fontSize: 13,
                    color: 'red',
                    fontWeight: 'bold'
                  }}
                >
                  Login Timer: {op.timerOnScreenToBlock}
                </Typography>
                <FaRobot size={20} color="red" />
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: 30,
                mt: 0.3
              }}
            >
              <Button
                onClick={() => setShowDialogCalc(true)}
                sx={{ width: '100%', borderRadius: 0.5, borderColor: '#5243E2' }}
                variant="outlined"
              >
                <Typography
                  sx={{
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    width: '80%',
                    fontSize: 12,
                    color: '#5243E2',
                    fontWeight: 'bold'
                  }}
                >
                  Cálculo de acesso
                </Typography>
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>

      <MakeDialog />
      <MakeDialogNginx />
      <MakeDialogFlushIPS />
      <UnlockIpDialog isOpen={showDialoUnlockIp} handleClose={handleCloseIp} />
      <TimerOnScreenModal
        isOpen={showDialogMinimunTimer}
        handleClose={handleCloseModalMinimumTimer}
      />

      <CalcAcessoModal isOpen={showDialogCalc} handleClose={handleCloseModalCalc} />

      {userData && userData[0].role === 'ADMIN' ? (
        <NavSection navConfig={sidebarConfigAdmin} />
      ) : (
        <NavSection navConfig={sidebarConfig} />
      )}
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
          px: 2.5
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            fontWeight: 'bold',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <BsFillHddNetworkFill size={11} />
          &nbsp;
          <span style={{ color: '#200B94', fontSize: 11 }}>149.56.152.211</span>
          <span style={{ color: '#200B94', fontSize: 11 }}> &nbsp;|&nbsp; Running</span>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Button
          sx={{
            borderRadius: 0.5,
            width: '100%',
            bgcolor: 'red',
            '&:hover': { borderColor: '#DD4913', bgcolor: '#DD4913', color: 'white' }
          }}
          onClick={handleLogout}
          variant="contained"
        >
          Sair&nbsp;
          <AiOutlineLogout size={15} color="white" />
        </Button>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
